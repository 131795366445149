<template>
  <v-card>
    <v-card-text class="pa-0">
      <v-toolbar card color="white">
        <v-text-field
          flat
          solo
          prepend-icon="search"
          placeholder="Filtrar"
          v-model="search"
          hide-details
          class="hidden-sm-and-down"
        ></v-text-field>
        <v-btn icon @click="cadastro">
          <v-icon>add_circle_outline</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="sacolas"
        :rows-per-page-items="[10, 25, 50, { text: 'All', value: -1 }]"
        class="elevation-1"
      >
        <template v-slot:items="props">
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.quantidade }}</td>
          <td>{{ props.item.data }}</td>
          <td>{{ props.item.nomeUsuario }}</td>
          <td>
            <v-btn @click="visualizar(props.item.id)" depressed icon dark color="primary" small>
              <v-icon small>remove_red_eye</v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import ServicoSacola from "../../servicos/servicoSacola"
const servicoSacola = new ServicoSacola()
export default {
  data() {
    return {
      search: "",
      sacolas: [],
      headers: [
        { text: "Código", align: "center", value: "id", sortable: false },
        { text: "Quantidade", align: "center", value: "quantidade", sortable: false },
        { text: "Data inclusão", value: "dataInclusao", sortable: false },
        { text: "Usuário", value: "usuario", sortable: false },
        { text: "", value: "action", align: "right", sortable: false }
      ]
    }
  },
  created() {
    servicoSacola.getAll().then(res => {
      if (res.status === 200) {
        if (res.data.length > 0 && res.data[0].status == 412) {
          this.snackbar = true
          this.text = res.data[0].detail
        } else {
          this.sacolas = res.data
        }
      }
    })
  },
  methods: {
    cadastro() {
      this.$router.push({ name: "cadastro-sacola" })
    },
    visualizar(id) {
      this.$router.push({ name: "view-sacola", params: { id: id } })
    }
  }
}
</script>
