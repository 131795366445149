<template>
  <div id="pageSacola">
    <v-container grid-list-xl fluid>
      <v-layout row wrap justify-center>
        <v-flex lg10 sm12 xs12>
          <sacola-table></sacola-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import SacolaTable from "@/components/sacola/tableSacola"
export default {
  components: {
    SacolaTable
  }
}
</script>
